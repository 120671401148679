import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { sendPasswordResetEmail } from "firebase/auth";
import { AUTH_LOGIN } from '../../../Config/firebase';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LOGIN_BG from '../../../Assets/Images/Common/loginBg.png';
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import Constant from "../../../Utils/Constant";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email Address Is Required!").matches(Constant.REGEX.EMAIL, "Please Provide Valid Email Address!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          await sendPasswordResetEmail(AUTH_LOGIN, values.email).then((response) => {
            toast.success("Success! A confirmation email has been sent to your registered address.")
            navigate('/login');
            setIsLoading(false);
            resetForm();
          })
        } catch (error) {
          console.log('error', error);
          toast.error('Oops! Something went wrong while sending the email. Please try again later.');
        }
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${LOGIN_BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
          }}
        >
          <Box
            sx={{
              width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },

            }}
          >
            <Box
              sx={{
                width: "100%",
                border: "2px solid #D0BCFF",
                // box-shadow: 0px 4px 20.8px 0px #00000040;
                backgroundColor: '#00000040',
                borderRadius: "30px",
                py: 1,
                position: "relative",
                zIndex: "3",
                boxShadow: '0px 4px 20.8px 0px #00000040',
                opacity: 1
              }}
            >
              <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                <Typography sx={{ fontSize: "35px", fontWeight: "600", color: '#fff', fontFamily: "Outfit", }}>
                  Forgot Password!
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400", color: '#fff', fontFamily: "Outfit", }}>
                  Provide your email to recover your password.
                </Typography>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}
                  style={{
                    width: "100%",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "1rem",
                    paddingTop: "30px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <CustomTextField
                      label="Email"
                      type="text"
                      placeholder="Email Address"
                      name="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      errors={formik.touched.email && formik.errors.email}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    // isLoading={isLoading}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "55%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomSubmitButton
                      btnTitle="Reset Password"
                      loading={isLoading}
                      type="submit"
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "55%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button sx={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      outline: 'none',
                      fontSize: "16px",
                      color: '#fff',
                      textDecoration: "none",
                      cursor: 'pointer',
                      fontWeight: "500",
                      textTransform: 'capitalize',
                      fontFamily: "Outfit",
                    }} onClick={() => navigate('/login')}> <ArrowBackIosIcon />Back To Login</Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
