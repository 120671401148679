import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import { toast } from 'react-toastify';
import APILIST from '../../../Config/ApiList';
import AxiosInstance from '../../../Config/AxiosInstance';
const AddCoachNotes = (props) => {
    const { open, onClose, handleSubmitWorkout, handleOnClose, selectedWeekID, isSelectWorkOutCardPostion, editPlanWorkOutDetailsCoachNotes, setEditPlanWorkoutDetailsCoachNotes } = props;
    console.log('selectedWeekID', selectedWeekID);
    console.log('editPlanWorkOutDetailsCoachNotes', editPlanWorkOutDetailsCoachNotes);
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            coachNotes: '',
        },
        validationSchema: Yup.object().shape({
            coachNotes: Yup.string().required("coach notes is required!"),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const formData = new FormData();
                    formData.append('workout_week_id', selectedWeekID);
                    formData.append("status", 1);
                    if (editPlanWorkOutDetailsCoachNotes?.id) {
                        formData.append('day', editPlanWorkOutDetailsCoachNotes?.day);
                    } else {
                        formData.append('day', isSelectWorkOutCardPostion);
                    }
                    formData.append('notes', values.coachNotes);
                    if (values.comment) {
                        formData.append('comment', values.comment);
                    }
                    if (editPlanWorkOutDetailsCoachNotes?.id) {
                        formData.append('id', editPlanWorkOutDetailsCoachNotes?.id);
                    }
                    const apiEndPoint = editPlanWorkOutDetailsCoachNotes?.id ? APILIST.ADD_UPDATE_COACHNOTES : APILIST.ADD_UPDATE_COACHNOTES;
                    const response = await AxiosInstance.post(apiEndPoint, formData);
                    if (response && response?.data?.s) {
                        toast.success(editPlanWorkOutDetailsCoachNotes?.id ? "Coach Notes Updated Successfully!" : 'Coach Notes Added Successfully!');
                        resetForm();
                        handleSubmitWorkout();
                    } else {
                        toast.error(response?.data?.m);
                    }
                } catch (error) {
                    console.error('Error adding workout details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    });
    useEffect(() => {
        if (editPlanWorkOutDetailsCoachNotes?.id) {
            formik.setValues({
                coachNotes: editPlanWorkOutDetailsCoachNotes.notes || '',
            });
        } else {
            formik.resetForm();
        }
    }, [editPlanWorkOutDetailsCoachNotes]);
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={() => {
                    onClose();
                    formik.resetForm(); setEditPlanWorkoutDetailsCoachNotes({});
                }}
                maxWidth="sm"
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "20px",
                        padding: '20px 30px',
                        height: "auto",
                        overflow: "scroll",
                        "::-webkit-scrollbar": { width: "0px", height: '0px' }
                    },
                    "& .MuiDialogContent-root": {
                        overflowY: "initial",
                    },
                    "& .MuiBackdrop-root": {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }
                }}
            >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>{editPlanWorkOutDetailsCoachNotes?.id ? "Update" : "Add"} Coach Notes</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                                <IconButton sx={{
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#101010',
                                    borderRadius: '8px',
                                    "&:hover": {
                                        backgroundColor: '#101010',
                                        color: '#fff',
                                    }
                                }} onClick={() => {
                                    handleOnClose();
                                    formik.resetForm(); setEditPlanWorkoutDetailsCoachNotes(null)
                                }}>
                                    <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        padding: '20px 0px 0px 0px',
                    }}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <CustomTextField
                                    label="Coach Notes"
                                    type="text"
                                    placeholder="Enter Coach Notes"
                                    name="coachNotes"
                                    multiline
                                    rows={2}
                                    value={formik.values.coachNotes}
                                    onBlur={formik.handleBlur}
                                    errors={formik.touched.coachNotes && formik.errors.coachNotes}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.trimStart() !== value) {
                                            e.target.value = value.trimStart();
                                        }
                                        formik.handleChange(e);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "10px",
                                            border: formik.errors.coachNotes ? "1px solid red" : "1px solid #BB9EFF",
                                            color: "black",
                                            fontSize: "18px",
                                            background: "white",
                                            fontFamily: "Outfit",
                                        },
                                    }}
                                    style={{ color: "black", fontSize: "18px" }}
                                    isLoading={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomSubmitButton
                                    btnTitle={editPlanWorkOutDetailsCoachNotes?.id ? "Update" : "Done"}
                                    loading={isLoading}
                                    type="submit"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Dialog>
        </Fragment>

    );
};

export default AddCoachNotes;