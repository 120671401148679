import React, { Fragment, useState } from 'react';
import { Box, Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AddWorkoutModel from './AddWorkoutDetailsModel';
import CustomAction from '../../../Components/CustomAction/CustomAction';
import Swal from 'sweetalert2';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { toast } from 'react-toastify';
import AddCoachNotes from './AddCoachNotes';

const PlanWorkOutList = (props) => {
    const {
        selectedWeekID,
        setIsOpenAddWorkOutModel,
        setIsOpenAddWorkOutModel_1,
        isOpenAddWorkOutModel,
        isOpenAddWorkOutModel_1,
        selectedWeekData,
        isSelectedDays,
        isSelectedDay,
        handleSubmitWorkout,
        onDeleteUpdate,
        handleDeletePlandetailsDayAllPosition,
        value
    } = props;
    const [isSelectWorkOutCardPostion, setIsSelectWorkOutPostion] = useState(null);
    const [isSelectWorkOutCardPostion_1, setIsSelectWorkOutPostion_1] = useState(null);
    const [editPlanWorkOutDetails, setEditPlanWorkoutDetails] = useState({});
    const [editPlanWorkOutDetailsCoachNotes, setEditPlanWorkoutDetailsCoachNotes] = useState({});
    const shouldShowAddWorkout = (day) => {
        if (!selectedWeekData || !selectedWeekData.workout_details) return true;
        const workoutsForDay = selectedWeekData.workout_details.filter(
            workout => workout.day == day
        );
        return workoutsForDay.length === 0;
    };
    const filteredPlanDays = Array.from(new Set(isSelectedDays || [])).sort((a, b) => a - b);
    const handleAddWorkOut = (position) => {
        const day = Object.keys(position)[0];
        setIsOpenAddWorkOutModel(true);
        setIsSelectWorkOutPostion(day);
    };
    const handleAddWorkOutCoachNotes = (position) => {
        const day = Object.keys(position)[0];
        setIsOpenAddWorkOutModel_1(true);
        setIsSelectWorkOutPostion_1(day);
    }
    const handleDeletePlanDetails = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this workout",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const params = {
                    id: id,
                    status: 0,
                };
                try {
                    const response = await AxiosInstance.post(
                        APILIST.UPDATE_DELETE_WORKOUT_DETAILS,
                        params
                    );
                    if (response?.data?.s === 1) {
                        toast.success("Workout Deleted Sucessfully !.");
                        onDeleteUpdate();
                    }
                } catch (error) {
                    toast.error("Failed to delete plan details . Please try again.");
                }
            }
        });
    };
    const handleDeleteCoachNotes = async (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this coach notes",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.append("workout_week_id", selectedWeekID);
                formData.append("day", data?.day);
                formData.append("notes", data?.notes);
                formData.append("status", 0)
                try {
                    const response = await AxiosInstance.post(
                        APILIST.ADD_UPDATE_COACHNOTES,
                        formData
                    );
                    if (response?.data?.s === 1) {
                        toast.success("Coach Notes Deleted Sucessfully !.");
                        onDeleteUpdate();
                    }
                } catch (error) {
                    toast.error("Failed to delete coach notes . Please try again.");
                }
            }
        });
    };
    const handleEditPlanDetails = (data) => {
        setIsOpenAddWorkOutModel(true);
        if (data?.id) {
            setEditPlanWorkoutDetails(data);
        }
    }
    const handleEditPlanDetails1 = (data) => {
        setIsOpenAddWorkOutModel_1(true);
        if (data?.id) {
            setEditPlanWorkoutDetailsCoachNotes(data);
        }
    }
    return (
        <Fragment>
            {filteredPlanDays && (
                <Box sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '15px', padding: '20px', height: '500px', overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: 'black', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '18px' }, mb: '8px' }}>
                            Plan For {filteredPlanDays.length} {filteredPlanDays.length > 1 ? 'days' : 'day'}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {selectedWeekData?.workout_details?.filter(workout => workout?.day == isSelectedDay)?.length > 0 && (
                                <Button sx={{
                                    width: '70px',
                                    background: "rgba(204, 51, 0, 1)",
                                    color: '#fff',
                                    fontSize: '10px',
                                    fontWeight: '400',
                                    borderRadius: '8px',
                                    mb: "10px",
                                    "&:hover": {
                                        background: "rgba(204, 51, 0, 1)",
                                        color: '#fff',
                                    }
                                }} onClick={() => handleDeletePlandetailsDayAllPosition(selectedWeekData?.workout_details, selectedWeekData?.workout_notes)}>
                                    Delete
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Grid container spacing={3}>
                        {filteredPlanDays?.map((elem, index) => {
                            const day = Object.keys(elem)[0];
                            const workoutsForDay = selectedWeekData?.workout_details ? selectedWeekData.workout_details.filter(
                                workout => workout?.day == day
                            ) : [];
                            const coachBotesForDay = selectedWeekData?.workout_notes ? selectedWeekData.workout_notes.filter(
                                workout => workout?.day == day
                            ) : [];
                            const showAddWorkout = shouldShowAddWorkout(day, isSelectedDay);
                            return (
                                <Grid item xs={12} key={index}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '10px',
                                        background: "rgba(247, 244, 255, 1)",
                                        padding: '12px 10px',
                                        borderRadius: '8px'
                                    }}>
                                        <Typography sx={{ color: '#B190FF', fontWeight: '400', textAlign: 'left', fontSize: { xs: '16px', sm: '18px' }, mb: '10px' }}>
                                            {`Day ${day}`}
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                            {workoutsForDay?.length > 0 && coachBotesForDay?.length < 1 ? <Button sx={{
                                                color: '#fff',
                                                background: "#B190FF",
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                borderRadius: '8px',
                                                "&:hover": {
                                                    color: '#fff',
                                                    background: "#B190FF",
                                                }
                                            }} onClick={() => handleAddWorkOutCoachNotes({ [day]: [] })}>Coach Notes</Button> : null}
                                            {workoutsForDay?.length > 0 ? <Button sx={{
                                                color: '#fff',
                                                background: "#B190FF",
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                borderRadius: '8px',
                                                "&:hover": {
                                                    color: '#fff',
                                                    background: "#B190FF",
                                                }
                                            }} onClick={() => handleAddWorkOut({ [day]: [] })}>Add More</Button> : null}
                                        </Box>
                                    </Box>
                                    {coachBotesForDay?.length > 0 && <Box sx={{
                                        paddingBottom: '20px'
                                    }}>
                                        <Typography sx={{ color: '#B190FF', fontWeight: '400', textAlign: 'left', fontSize: { xs: '15px', sm: '15px' }, mb: '10px' }}>
                                            {`Coach Notes`}
                                        </Typography>
                                        <TableContainer sx={{
                                            border: "1px solid rgba(41, 45, 50, 0.5)",
                                            borderRadius: '8px'
                                        }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="workout table">
                                                <TableBody>
                                                    {coachBotesForDay?.map((workout, workoutIndex) => (
                                                        <TableRow key={workoutIndex}>
                                                            <TableCell component="th" scope="row" sx={{}}>{workout?.notes}</TableCell>
                                                            <TableCell align="right" sx={{ width: '100px', borderLeft: '1px solid rgba(41, 45, 50, 0.5)' }}>
                                                                <CustomAction
                                                                    showDelete={true}
                                                                    showEdit={true}
                                                                    isDelete={() => handleDeleteCoachNotes(workout)}
                                                                    isEdit={() => handleEditPlanDetails1(workout)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>}
                                    {workoutsForDay?.length > 0 ? (
                                        <>
                                            <Typography sx={{ color: '#B190FF', fontWeight: '400', textAlign: 'left', fontSize: { xs: '15px', sm: '15px' }, mb: '10px' }}>
                                                {`Workout`}
                                            </Typography>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="workout table">
                                                    <TableBody>
                                                        {workoutsForDay?.map((workout, workoutIndex) => (
                                                            <TableRow key={workoutIndex}>
                                                                <TableCell component="th" scope="row">{workoutIndex + 1}</TableCell>
                                                                <TableCell component="th" scope="row">{workout?.name}</TableCell>
                                                                <TableCell align="right">{workout?.value}</TableCell>
                                                                <TableCell align="right">{workout?.type === 1 ? "Min" : "Mile"}</TableCell>
                                                                <TableCell align="right">
                                                                    <CustomAction
                                                                        showDelete={true}
                                                                        showEdit={true}
                                                                        isDelete={() => handleDeletePlanDetails(workout?.id)}
                                                                        isEdit={() => handleEditPlanDetails(workout)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    ) : showAddWorkout && (
                                        <Box
                                            sx={{
                                                borderRadius: '20px',
                                                border: '1px solid #D0BCFF',
                                                height: '100px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                mt: workoutsForDay.length > 0 ? 2 : 0
                                            }}
                                            onClick={() => handleAddWorkOut({ [day]: [] })}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#B190FF',
                                                    fontWeight: '400',
                                                    fontSize: { xs: '15px', sm: '15px' },
                                                    '&:hover': { textDecoration: 'underline' },
                                                }}
                                            >
                                                Add Workout
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
            <AddWorkoutModel
                open={isOpenAddWorkOutModel}
                onClose={() => { setIsOpenAddWorkOutModel(false); }}
                handleOnClose={() => { setIsOpenAddWorkOutModel(false); }}
                selectedWeekID={selectedWeekID}
                isSelectWorkOutCardPostion={isSelectWorkOutCardPostion}
                editPlanWorkOutDetails={editPlanWorkOutDetails}
                handleSubmitWorkout={handleSubmitWorkout}
                setEditPlanWorkoutDetails={setEditPlanWorkoutDetails}
            />
            <AddCoachNotes
                open={isOpenAddWorkOutModel_1}
                onClose={() => { setIsOpenAddWorkOutModel_1(false); }}
                handleOnClose={() => { setIsOpenAddWorkOutModel_1(false); }}
                selectedWeekID={selectedWeekID}
                isSelectWorkOutCardPostion={isSelectWorkOutCardPostion_1}
                editPlanWorkOutDetailsCoachNotes={editPlanWorkOutDetailsCoachNotes}
                handleSubmitWorkout={handleSubmitWorkout}
                setEditPlanWorkoutDetailsCoachNotes={setEditPlanWorkoutDetailsCoachNotes}
            />
        </Fragment>
    );
};

export default PlanWorkOutList;
